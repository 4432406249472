import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { convert } from "cashify";
import { ExchangeRateContract } from "@ttoengineering/delphi";

const getCurrency = (currencyCode: string) => {
  switch (currencyCode) {
    case "EUR":
      return "EUR";
    case "USD":
      return "USD";
    case "GBP":
      return "GBP";
    case "AUD":
      return "AUD";
    case "DKK":
      return "DKK";
    case "ZAR":
      return "ZAR";
    case "CAD":
      return "CAD";
    default:
      return "GBP";
  }
};

interface CurrencyContextProps {
  setCurrency: (currency: string) => void;
  formatPriceWithCurrency: (
    smallestUnit: number,
    showDecimal?: boolean,
    currencyCode?: string
  ) => string;
  convertFromCurrencyToSelectedCurrency: (
    smallestUnit: number,
    currencyCode: string
  ) => number;
  exchangeRates?: ExchangeRateContract[];
  selectedCurrency: string;
  isLoading: boolean;
}

export const CurrencyContext = createContext<CurrencyContextProps>({} as any);

export function useCurrencyContext() {
  return useContext(CurrencyContext);
}

interface CurrencyContextProviderProps {
  children: ReactNode;
}

export const CurrencyContextProvider = ({
  children,
}: CurrencyContextProviderProps) => {
  const [exchangeRates, setExchangeRates] = useState<ExchangeRateContract[]>();
  const [selectedCurrency, setSelectedCurrency] = useState<string>("GBP");
  const [isLoading, setIsLoading] = useState(false);

  const setCurrency = (currency: string) => {
    window.localStorage.setItem("currency", currency);
    setSelectedCurrency(currency);
  };

  const fetchExchangeRates = async () => {
    setIsLoading(true);
    const url = `${process.env.NEXT_PUBLIC_STRAPI_URL}/exchange-rates`;
    const response = await fetch(url);
    const json = await response.json();
    setExchangeRates(json);
    setIsLoading(false);
  };

  useEffect(() => {
    const currency = window.localStorage.getItem("currency");
    if (currency) {
      setSelectedCurrency(currency);
    }
    fetchExchangeRates();
  }, []);

  const convertFromCurrencyToSelectedCurrency = (
    amount: number,
    currencyCode: string
  ) => {
    if (!exchangeRates) {
      return 0;
    }
    const rates = {} as any;

    exchangeRates.forEach((er) => (rates[er.currencyCode] = er.rate));

    return convert(amount, {
      from: currencyCode,
      to: selectedCurrency,
      base: "GBP",
      rates,
    });
  };

  const formatPriceWithCurrency = (
    smallestUnit: number,
    showDecimal = false,
    currencyCode = "GBP"
  ) => {
    const convertedPrice =
      convertFromCurrencyToSelectedCurrency(smallestUnit, currencyCode) / 100;
    return convertedPrice.toLocaleString("en-US", {
      style: "currency",
      currency: selectedCurrency,
      maximumFractionDigits: showDecimal ? 2 : 0,
      minimumFractionDigits: showDecimal ? 2 : 0,
    });
  };

  return (
    <CurrencyContext.Provider
      value={{
        setCurrency,
        formatPriceWithCurrency,
        convertFromCurrencyToSelectedCurrency,
        exchangeRates,
        selectedCurrency,
        isLoading,
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};
