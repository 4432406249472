import type { AppProps } from "next/app";
import Head from "next/head";
import styled, { ThemeProvider } from "styled-components";
import { ToastContainer } from "react-toastify";
import smoothscroll from "smoothscroll-polyfill";
import { hotjar } from "react-hotjar";
import CookieConsent from "react-cookie-consent";
import posthog from "posthog-js";

import { GlobalStyle, theme } from "../utils/theme";
import { AmplitudeContextProvider } from "../contexts/AmplitudeContext";
import { CurrencyContextProvider } from "../contexts/CurrencyContext";
import { useEffect } from "react";

import "react-dates/lib/css/_datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import "react-image-gallery/styles/css/image-gallery.css";
import { useRouter } from "next/router";
import { SegmentAnalyticsProvider } from "../contexts/SegmentContext";

const ToastContainerSt = styled(ToastContainer)`
  .Toastify__toast-body {
    color: ${theme.colors.jet};
  }
`;

if (typeof window !== "undefined") {
  // This ensures that as long as we are client-side, posthog is always ready
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_API_KEY as string, {
    api_host: "https://eu.posthog.com",
    loaded: (posthog) => {
      if (process.env.NODE_ENV === "development") posthog.opt_out_capturing();
    },
  });
}

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  useEffect(() => {
    smoothscroll.polyfill();
    if (process.env.NEXT_PUBLIC_ENVIRONMENT === "production") {
      process.env.NEXT_PUBLIC_HOTJAR_ID &&
        hotjar.initialize(parseInt(process.env.NEXT_PUBLIC_HOTJAR_ID), 6);
    }
  }, []);

  useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog.capture("$pageview");
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <ThemeProvider theme={theme}>
      <Head>
        <link rel="shortcut icon" href="/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link
          href="https://api.mapbox.com/mapbox-gl-js/v0.51.0/mapbox-gl.css"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Manrope:wght@500;600;700&display=swap"
          rel="stylesheet"
        />
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <meta
          name="p:domain_verify"
          content="eb930e17370f83bd9a41164ea3bc7230"
        />
        <meta
          name="google-site-verification"
          content="lo6JpkLi-1Eb9OGzr3pQMrrZ6t2L5ikC1V5LdbxyVfg"
        />
      </Head>
      <SegmentAnalyticsProvider writeKey={`${process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY}`}>
        <AmplitudeContextProvider>
          <CurrencyContextProvider>
            <Component {...pageProps} />
          </CurrencyContextProvider>
        </AmplitudeContextProvider>
      </SegmentAnalyticsProvider>
      <GlobalStyle />
      <ToastContainerSt />
      <CookieConsent
        style={{ justifyContent: "unset" }}
        contentStyle={{
          justifyContent: "unset",
          // flex: 1,
          width: "fit-content",
          flex: "unset",
        }}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </ThemeProvider>
  );
}

export default MyApp;
