import { createGlobalStyle } from 'styled-components'

export const theme = {
  colors: {
    brandRed: '#F93943' as '#F93943',
    darkRed: '#D6353D' as '#D6353D',
    orange: '#ffa500' as '#ffa500',
    text01: '#3c3c3c' as '#3c3c3c',
    text02: '#333333' as '#333333',
    text03: '#717171' as '#717171',
    ui01: '#C4C4C4' as '#C4C4C4',
    ui02: '#E5E5E5' as '#E5E5E5',
    peach: '#F2898E' as '#F2898E',
    lightOrange: '#FAF1EB' as '#FAF1EB',
    terracotta: '#D28172' as '#D28172',
    jet: '#383838' as '#383838',
    pebble: '#afafaf' as '#afafaf',
    darkGrey: '#333333' as '#333333',
    lightGrey: '#EFEFEF' as '#EFEFEF',
    white: '#fff' as '#fff',
  },
  breakpoints: {
    xsm: `@media (min-width: 320px)` as `@media (min-width: 320px)`, // Small Mobile (Iphone 5)
    sm: `@media (min-width: 375px)` as `@media (min-width: 375px)`, // Mobile
    md: `@media (min-width: 768px)` as `@media (min-width: 768px)`, // Small Tablet
    mlg: `@media (min-width: 992px)` as `@media (min-width: 992px)`, // Large Tablet
    lg: `@media (min-width: 1280px)` as `@media (min-width: 1280px)`, // Small Laptop
    xlg: `@media (min-width: 1440px)` as `@media (min-width: 1440px)`, // Medium Laptop
    xxl: `@media (min-width: 1680px)` as `@media (min-width: 1680px)`, //  Large Laptop
    xxxl: `@media (min-width: 1920px)` as `@media (min-width: 1920px)`, //  Desktop
    fourXl: `@media (min-width: 2560px)` as `@media (min-width: 2560px)`, //  Large Desktop
    fiveXl: `@media (min-width:  3840px)` as `@media (min-width: 3840px)`, //  Extra Large Desktop
  },
  breakpointPixels: {
    xsm: 320,
    sm: 375,
    md: 768,
    mlg: 992,
    lg: 1200,
    xlg: 1600,
  },
  boxShadows: {
    shadow1: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    shadow2: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    shadow3: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
    shadow4: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
    shadow5: '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)',
  },
}

// tslint:disable-next-line: variable-name
export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html,
  body {
    margin: 0;
    box-sizing: border-box;
    font-family: Poppins, 'Montserrat';
    font-weight: 400;
    font-size: 14px;

    // ${({ theme }) => `${theme.breakpoints.md} {
    //   font-size: 16px;
    // }`}

    // ${({ theme }) => `${theme.breakpoints.xlg} {
    //   font-size: 16px;
    // }`}

    ${({ theme }) => `${theme.breakpoints.xxl} {
      font-size: 16px;
    }`}

    ${({ theme }) => `${theme.breakpoints.fiveXl} {
      font-size: 24px;
    }`}
  }

  button {
    border: none;
    background: none;

    :focus {
      outline: ${theme.colors.brandRed} auto 5px;
    }
  }

  input:focus {
    outline: none;
  }

`
