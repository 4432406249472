import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import amplitude from "amplitude-js";

interface AmplitudeContextProps {
  amplitudeClient: any;
  amplitudeSetUserId: (userId: string) => void;
  amplitudeSetUserProperties: (userProperties: UserProperties) => void;
  amplitudeLogEvent: (
    eventType: AmplitudeEventType,
    eventProperties?: object
  ) => void;
  amplitudeIdentifyAdd: (
    userProperty: string,
    propertyIncrement: number
  ) => void;
  amplitudeIdentifyAppend: (
    userProperty: string,
    valueToAppend: number | string | object | Array<number | string | object>
  ) => void;
  amplitudeIdentifyPrepend: (
    userProperty: string,
    valueToPrepend: number | string | object | Array<number | string | object>
  ) => void;
  amplitudeIdentifySet: (
    userProperty: string,
    valueToSet:
      | number
      | string
      | object
      | boolean
      | Array<number | string | object | boolean>
  ) => void;
  amplitudeIdentifySetOnce: (
    userProperty: string,
    valueToSet:
      | number
      | string
      | object
      | boolean
      | Array<number | string | object | boolean>
  ) => void;
  amplitudeLogRevenue: (
    revenueInPence: number,
    product: string,
    quantity: number
  ) => void;
}

export const AmplitudeContext = createContext<AmplitudeContextProps>({} as any);

export function useAmplitudeContext() {
  return useContext(AmplitudeContext);
}

interface UserProperties {
  has_entered_email: boolean;
  has_enquired: boolean;
}

// Clicked, Selected, Submitted, Filled, Displayed

export enum AmplitudeEventType {
  SUBMITTED_HOMEPAGE_SEARCH = "Submitted Homepage Search",
  DISPLAYED_EMAIL_WALL_MODAL = "Displayed Email Wall Modal",
  SUBMITTED_EMAIL_WALL_MODAL = "Submitted Email From Wall Modal",
  SUBMITTED_EMAIL_WALL_PREFERENCE = "Submitted Email Preference From Wall Modal",
  CLICKED_VENUE_LIST_CARD = "Clicked Venue Card on List Page",
  DISPLAYED_VENUE_ENQUIRY_MODAL = "Displayed Venue Enquiry Modal",
  SUBMITTED_VENUE_ENQURY_MODAL = "Submitted Enquiry From Enquiry Modal",
  SUBMITTED_NAME_REFERRAL_MODULE = "Submitted Name From Referral Module",
  SUBMITTED_EMAIL_REFERRAL_MODULE = "Submitted Email From Referral Module",
  COPIED_CODE_REFERRAL_MODULE = "Copied Referral Code From Referral Module",
  COPIED_LINK_REFERRAL_MODULE = "Copied Referral Link From Referral Module",
  CLICKED_SOCIAL_SHARE_REFERRAL_MODULE = "Clicked One of The Social Icons on Referral Module",
  LOADED_VENUE_PROMOTION_1_PAGE_FROM_REFERRAL = "Loaded Venue Promotion 1 Page From Referral Link",
  LOADED_VENUE_SEARCH_PAGE_FROM_REFERRAL = "Loaded Venue Search Page From Referral Link",
  EXPERIMENT_1_EMAIL_WALL_MODAL_WIN = "Experiment 1 Win: Copy For Email Wall Modal",
  EXPERIMENT_2_EMAIL_WALL_MODAL_WIN = "Experiment 2 Win: Design of Email Wall Modal",
  DISPLAYED_GUEST_PAGE_IMPALA_HOTEL_CARD = "Displayed Guest Page Impala Hotel Card",
  EXPERIMENT_TIME_LIMIT_COPY_WIN = "Experiment Win: Copy best price guaranteed or time limit",
  GP_LOADED_PAGE = "Guest page loaded",
  GP_SUBMITTED_NAME_RSVP_MODULE = "Submitted Name Form From RSVP Module",
  GP_SUBMITTED_CUSTOM_QUESTION_RSVP_MODULE = "Submitted Custom Question Form From RSVP Module",
  GP_COMPLETED_RSVP_MODULE_ERROR = "Completed RSVP But Errored",
  GP_COMPLETED_RSVP_MODULE_SUCCESS = "Completed RSVP Successfully",
  GP_CLICKED_HOTEL_CARD_BOOK_BUTTON = "Clicked Book Hotel Button on Hotel Card",
  GP_CLICKED_ROOM_CARD_RESERVE_BUTTON = "Clicked Reserve Room Button on Room Card",
  GP_SUBMITTED_HOTEL_PAYMENT_ERROR = "Submitted Hotel Payment Error",
  GP_SUBMITTED_HOTEL_PAYMENT_SUCCESS = "Submitted Hotel Payment Success",
  HOTEL_CAPSULE_V2_LOADED_PAGE = "Hotel capsule v2 loaded page",
  HOTEL_CAPSULE_V2_CLICKED_RESERVE_BUTTON = "Hotel capsule v2 clicked reserve button",
  HOTEL_CAPSULE_V2_SUBMITTED_BILLING_DETAILS = "Hotel capsule v2 submitted billing details",
  HOTEL_CAPSULE_V2_SUBMITTED_BILLING_DETAILS_FOR_PAY_LATER = "Hotel capsule v2 submitted billing details for Pay Later",
  HOTEL_CAPSULE_V2_PAID = "Hotel capsule v2 paid for a room",
  HOTEL_CAPSULE_V2_PAID_DEPOSIT = "Hotel capsule v2 paid deposit for a room",
  HOTEL_CAPSULE_V2_CLCIKED_RESERVE_ANOTHER_ROOM = "Hotel capsule v2 clicked reserve another room",
}

interface AmplitudeContextProviderProps {
  children: ReactNode;
}

// N.B. AMPLITUDE JS SDK REFERENCE: https://amplitude.github.io/Amplitude-JavaScript/

export const AmplitudeContextProvider = ({
  children,
}: AmplitudeContextProviderProps) => {
  // const [amplitudeClient, setAmplitudeClient] = useState<typeof AmplitudeClient>()
  const [amplitudeClient, setAmplitudeClient] = useState<any>();

  useEffect(() => {
    const AMPLITUDE_API_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY;

    if (!amplitudeClient && AMPLITUDE_API_KEY) {
      const ampInstance = amplitude.getInstance();
      ampInstance.init(
        AMPLITUDE_API_KEY,
        undefined,
        { includeReferrer: true, includeUtm: true },
        function () {
          console.log("Amplitude init complete");
        }
      );
      setAmplitudeClient(ampInstance);
    }
  }, [amplitudeClient]);

  const amplitudeSetUserId = (userId: string) => {
    if (!amplitudeClient) return console.log("Amplitude not initialised");

    amplitudeClient.setUserId(userId);
  };

  const amplitudeSetUserProperties = (userProperties: UserProperties) => {
    if (!amplitudeClient) return console.log("Amplitude not initialised");

    amplitudeClient.setUserProperties(userProperties);
  };

  const amplitudeLogEvent = (
    eventType: AmplitudeEventType,
    eventProperties?: object
  ) => {
    if (!amplitudeClient) return console.log("Amplitude not initialised");

    amplitudeClient.logEvent(eventType, eventProperties);
  };

  const amplitudeIdentifyAdd = (
    userProperty: string,
    propertyIncrement: number
  ) => {
    /*
      Increment a user property by a given value (can also be negative to decrement).
      If the user property does not have a value set yet, it will be initialized to 0 before being incremented.
    */
    if (!amplitudeClient) return console.log("Amplitude not initialised");

    const identify = new amplitudeClient.Identify().add(
      userProperty,
      propertyIncrement
    );
    amplitudeClient.identify(identify); // send the Identify call
  };

  const amplitudeLogRevenue = (
    revenueInPence: number,
    product: string,
    quantity: number
  ) => {
    const amount = parseFloat((revenueInPence / 100).toFixed(2));

    const revenue = new amplitude.Revenue()
      .setProductId(product)
      .setPrice(amount)
      .setQuantity(quantity)
      .setRevenueType("income");

    amplitudeClient.logRevenueV2(revenue);
  };

  const amplitudeIdentifyAppend = (
    userProperty: string,
    valueToAppend: number | string | object | Array<number | string | object>
  ) => {
    if (!amplitudeClient) return console.log("Amplitude not initialised");

    const identify = new amplitudeClient.Identify().append(
      userProperty,
      valueToAppend
    );
    amplitudeClient.identify(identify);
  };

  const amplitudeIdentifyPrepend = (
    userProperty: string,
    valueToPrepend: number | string | object | Array<number | string | object>
  ) => {
    if (!amplitudeClient) return console.log("Amplitude not initialised");

    const identify = new amplitudeClient.Identify().prepend(
      userProperty,
      valueToPrepend
    );
    amplitudeClient.identify(identify);
  };

  const amplitudeIdentifySet = (
    userProperty: string,
    valueToSet:
      | number
      | string
      | object
      | boolean
      | Array<number | string | object | boolean>
  ) => {
    // Sets the value of a given user property. If a value already exists, it will be overwriten with the new value.
    if (!amplitudeClient) return console.log("Amplitude not initialised");

    const identify = new amplitudeClient.Identify().set(
      userProperty,
      valueToSet
    );
    amplitudeClient.identify(identify); // send the Identify call
  };

  const amplitudeIdentifySetOnce = (
    userProperty: string,
    valueToSet:
      | number
      | string
      | object
      | boolean
      | Array<number | string | object | boolean>
  ) => {
    /*
      Sets the value of a given user property only once. Subsequent setOnce operations on that user property will be ignored;
      however, that user property can still be modified through any of the other operations.
      Useful for capturing properties such as 'initial_signup_date', 'initial_referrer', etc.
    */
    if (!amplitudeClient) return console.log("Amplitude not initialised");

    const identify = new amplitudeClient.Identify().setOnce(
      userProperty,
      valueToSet
    );
    amplitudeClient.identify(identify); // send the Identify call
  };

  return (
    <AmplitudeContext.Provider
      value={{
        amplitudeClient,
        amplitudeSetUserId,
        amplitudeSetUserProperties,
        amplitudeLogEvent,
        amplitudeIdentifyAdd,
        amplitudeIdentifyAppend,
        amplitudeIdentifyPrepend,
        amplitudeIdentifySet,
        amplitudeIdentifySetOnce,
        amplitudeLogRevenue,
      }}
    >
      {children}
    </AmplitudeContext.Provider>
  );
};
