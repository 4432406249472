import { AnalyticsBrowser } from '@segment/analytics-next';

import { createContext, ReactNode, useContext, useMemo } from 'react';

const AnalyticsContext = createContext<AnalyticsBrowser>(undefined!);

type Props = {
  writeKey: string;
  children: ReactNode;
};
export const SegmentAnalyticsProvider = ({ children, writeKey }: Props) => {
  const analytics = useMemo(
    () => AnalyticsBrowser.load({ writeKey }),
    [writeKey],
  );
  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};

// Create an analytics hook that we can use with other components.
export const useSegment = () => {
  const result = useContext(AnalyticsContext);

  if (!result) {
    throw new Error('Context used outside of its Provider!');
  }
  return result;
};
